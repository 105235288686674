import React from 'react';

import PIconView from './PIcon.view';

import icons from '../../assets/icons';
import { IconSize } from './PIcon.model';
import { Align } from 'chart.js';

type Props = {
  name: keyof typeof icons;
  className?: string;
  size?: IconSize;
  dataTestid?: string;
  width?: number;
  height?: number;
  align?: Align;
  fillColor?: string;
};

const PIcon: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <PIconView
      name={props.name}
      className={props.className}
      size={props.size}
      dataTestid={props.dataTestid}
      width={props.width}
      height={props.height}
      align={props.align}
      fillColor={props.fillColor}
    ></PIconView>
  );
};

PIcon.displayName = 'PIcon';

export default PIcon;
