import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import guards from '../../routes/guards';
import { FeaturesFlags } from 'models/features-flags';
import { Permissions } from 'models/permissions';
import { Path } from 'utils/enums/paths';
import { GuardData } from '../../routes/guards/guard.model';

// Pages routes
const Layout = React.lazy(() => import('../../pages/Layout/Layout'));
const WelcomePage = React.lazy(() => import('../../pages/WelcomePage/WelcomePage'));
const Resources = React.lazy(() => import('../../pages/Resources/Resources'));
const ConditionalAccess = React.lazy(() => import('../../pages/ConditionalAccess/ConditionalAccess'));
const Reports = React.lazy(() => import('../../pages/Reports/Reports'));
const Secrets = React.lazy(() => import('../../pages/Secrets/Secrets'));
const IncidentActive = React.lazy(() => import('../../pages/IncidentsActive/IncidentsActive'));
const IncidentHistory = React.lazy(() => import('../../pages/IncidentsHistory/IncidentsHistory'));
const UsersSettings = React.lazy(() => import('../../pages/Users/Users'));
const SensorDashboard = React.lazy(() => import('../../pages/SensorsDashboard/SensorsDashboard'));
const CVE = React.lazy(() => import('../../pages/CVE/CVE'));
const AccountSettings = React.lazy(() => import('../../pages/AccountSettings/AccountSettings'));
const Logout = React.lazy(() => import('../../components/LogoutContainer/LogoutContainer'));
const Warp = React.lazy(() => import('../../pages/Warp/Warp'));
const Upload = React.lazy(() => import('../../pages/Upload/Upload'));
const AuditOrg = React.lazy(() => import('../../pages/AuditOrg/AuditOrg'));
const AuditSelf = React.lazy(() => import('../../pages/AuditSelf/AuditSelf'));
const Findings = React.lazy(() => import('../../pages/Findings/Findings'));
const Usage = React.lazy(() => import('../../pages/Usage/Usage'));
const Health = React.lazy(() => import('../../pages/Health/Health'));
const CustomerFile = React.lazy(() => import('../../pages/CustomerFile/CustomerFile'));
const CustomerSupport = React.lazy(() => import('../../pages/CustomerSupport/CustomerSupport'));
const Ticket = React.lazy(() => import('../../pages/Ticket/Ticket'));

// Login routes
const Auth = React.lazy(() => import('../../pages/Auth/Auth'));
const TOTP = React.lazy(() => import('../../pages/TOTP/TOTP'));

// Registartion routes
const RegistrationOTP = React.lazy(() => import('../../pages/RegistrationOTP/RegistrationOTP'));
const Registration = React.lazy(() => import('../../pages/Registration/Registration'));
const RegistrationTOTP = React.lazy(() => import('../../pages/RegistrationTOTP/RegistrationTOTP'));

// Reset Password routes
const ResetPassword = React.lazy(() => import('../../pages/ResetPassword/ResetPassword'));
const ResetPasswordTOTP = React.lazy(() => import('../../pages/ResetPasswordTOTP/ResetPasswordTOTP'));
const ResetPasswordFullRecoveryTOTP = React.lazy(() => import('../../pages/ResetPasswordFullRecoveryTOTP/ResetPasswordFullRecoveryTOTP'));
const ResetPasswordFullRecoveryOTP = React.lazy(() => import('../../pages/ResetPasswordFullRecoveryOTP/ResetPasswordFullRecoveryOTP'));

// Prospect Responser routes
const ProspectOTP = React.lazy(() => import('../../pages/Prospect/Prospect'));

type BaseRoute = {
  path: string;
  module?: { code: string; action: string; fallback?: string } | string;
  guard?: ((guardData: GuardData) => boolean)[];
  fallback?: string;
  children?: PRoute[] | null;
};

type ComponentRoute = BaseRoute & {
  component: React.FC;
};

type JSXRoute = BaseRoute & {
  jsx: React.ReactNode;
};

export const useAuthenticatedRoutes = (): PRoute[] => {
  // prettier-ignore
  const routes: PRoute[] = useMemo(() => {
return [
  // routes that we able to render with dependecies (guard access)
  { path: '*', jsx: <Navigate to={Path.Welcome} replace></Navigate>, guard:[guards.authGuard], fallback:'/' },
  { path: Path.Welcome, component: WelcomePage,guard:[guards.authGuard], fallback: Path.Welcome },
  { path: Path.Health, component: Health,guard: [guards.accessGuard(FeaturesFlags.Health)], fallback: Path.Welcome },
  { path: Path.Usage, component: Usage,guard: [guards.accessGuard(FeaturesFlags.Usage)], fallback: Path.Welcome },
  { path: Path.CustomerFile, component: CustomerFile,guard: [guards.accessGuard(FeaturesFlags.CustomerFile, Permissions.CustomerFileRead)], fallback: Path.Welcome },
  { path: Path.CustomerSupport, component: CustomerSupport,guard: [guards.accessGuard(FeaturesFlags.CustomerSupport, Permissions.CustomerSupportList)], fallback: Path.Welcome },
  { path: `${Path.CustomerSupport}/:id`, component: Ticket,guard: [guards.accessGuard(FeaturesFlags.CustomerSupport, Permissions.CustomerSupportList)], fallback: Path.Welcome },
  { path: Path.Findings, component: Findings,guard:[guards.accessGuard(FeaturesFlags.Findings, Permissions.FindingList)], fallback: Path.Welcome }, 
  { path: Path.Reports, component: Reports,guard:[guards.accessGuard(FeaturesFlags.Reports, Permissions.ReportList)], fallback:'/' },
  { path: 'reports/:parameter/:org', component: Reports,guard:[guards.accessGuard(FeaturesFlags.Reports, Permissions.ReportList)], fallback:'/' },
  { path: 'resources/:parameter/:secondparameter/:org', component: Resources,guard:[guards.accessGuard(FeaturesFlags.Resources, Permissions.ResourcesList)], fallback:'/' },
  { path: Path.Resources, component: Resources,guard:[guards.accessGuard(FeaturesFlags.Resources, Permissions.ResourcesList)], fallback:'/' },
  { path: Path.ConditionalAccess, component: ConditionalAccess,guard:[guards.accessGuard(FeaturesFlags.ConditionalAccess, Permissions.OrgConditionalAccessRead)], fallback:'/' },
  { path: Path.Secrets, component: Secrets, guard:[guards.accessGuard(FeaturesFlags.Secrets, Permissions.SecretsList)], fallback:'/' },
  { path: Path.SensorsDashboard, component: SensorDashboard, guard:[guards.accessGuard(FeaturesFlags.SensorsDashboard, Permissions.SensorsRead)], fallback:'/' },
  { path: Path.CVE, component: CVE, guard:[guards.accessGuard(FeaturesFlags.Cve, Permissions.SensorsRead)], fallback:'/' },
  { path: Path.IncidentsActive, component: IncidentActive, guard:[guards.accessGuard(FeaturesFlags.IncidentsActive, Permissions.IncidentActiveList )], fallback:'/' },
  { path: Path.IncidentsHistory, component: IncidentHistory, guard:[guards.accessGuard(FeaturesFlags.IncidentsHistory, Permissions.IncidentHistoryList)], fallback:'/'},
  { path: Path.UsersSettings, component: UsersSettings, guard:[guards.accessGuard(FeaturesFlags.UsersSettings, Permissions.UsersList)], fallback:'/' },
  { path: Path.AuditOrg, component: AuditOrg, guard:[guards.accessGuard(FeaturesFlags.Audit, [Permissions.AuditOrgRead, Permissions.UsersList])], fallback:'/' },
  { path: Path.AuditSelf, component: AuditSelf, guard:[guards.accessGuard(FeaturesFlags.Audit, Permissions.AuditSelfRead)], fallback:'/' },
  { path: Path.AccountSettings, component: AccountSettings, guard: [guards.accessGuard(FeaturesFlags.AccountSettings, Permissions.UserSelfDetailsRead)], fallback:'/' },
  { path: Path.Warp, component: Warp, guard:[guards.accessGuard(FeaturesFlags.FileSharingStatus, Permissions.HomeEmergencyKeyRead)], fallback:'/' },
  { path: Path.Upload, component: Upload, guard:[guards.accessGuard(FeaturesFlags.FileSharingUsage, Permissions.HomeEmergencyKeyRead)], fallback:'/' },
  { path: Path.Logout, component: Logout },
]
  }, []);

  return routes;
};

export const useRoutes = (): PRoute[] => {
  const authenticatedRoutes = useAuthenticatedRoutes();

  const routes: PRoute[] = useMemo(() => {
    return [
      // routes that we able to render without authentication
      { path: '/*', component: Auth, guard: [guards.guestGuard] },
      { path: '/:link/:parameter/:org', component: Auth, guard: [guards.guestGuard] },
      { path: '/:link/:parameter/:secondparameter/:org', component: Auth, guard: [guards.guestGuard] },
      { path: '/2fa-code', component: TOTP, guard: [guards.guestGuard] },
      { path: '/e1001/:id', component: RegistrationOTP, guard: [guards.guestGuard] },
      { path: '/registration', component: Registration, guard: [guards.guestGuard] },
      { path: '/totp', component: RegistrationTOTP, guard: [guards.guestGuard] },
      { path: '/reset-password', component: ResetPassword, guard: [guards.guestGuard] },
      { path: '/r1492/:id', component: ResetPasswordTOTP, guard: [guards.guestGuard] },
      { path: '/reset-password-full-totp', component: ResetPasswordFullRecoveryTOTP, guard: [guards.guestGuard] },
      { path: '/r1973/:id', component: ResetPasswordFullRecoveryOTP, guard: [guards.guestGuard] },
      { path: '/p6506/:id', component: ProspectOTP, guard: [guards.guestGuard] },
      // once we logged in (we have a user stored in the store) - we are able to see the layout componenet (inside the portal).
      // the children of the layout are routes that need access check
      { path: '/*', component: Layout, guard: [guards.authGuard], children: authenticatedRoutes },
    ];
  }, [authenticatedRoutes]);

  return routes;
};

export type PRoute = ComponentRoute | JSXRoute;

export default useRoutes;
