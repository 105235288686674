import React from 'react';

import PSpinner from 'views/PSpinner/PSpinner';

import classes from './PLoading.module.scss';
import { SpinnerSize } from 'views/PSpinner/PSpinner.model';

type Props = {
  show: boolean;
  percent: number | null;
  header?: string;
  autoLogin?: boolean;
  dataTestid?: string;
};

const PLoadingView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return props.show ? (
    <div data-testid="pageLoader" className={`${classes['container']} ${props.autoLogin ? classes['container--white'] : ''}`.trim()}>
      <div className={classes['spinner']}>
        <PSpinner dataTestid={props.dataTestid} size={SpinnerSize.Large}></PSpinner>
        {props.percent === null ? null : (
          <React.Fragment>
            <br />
            <div className={classes['percent']}>
              {props.header} {props.percent || props.percent === 0 ? props.percent + '%' : ''}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  ) : null;
};

PLoadingView.displayName = 'PLoadingView';

export default PLoadingView;
