const ticket = {
  title: 'customer support',

  actions: {
    closeTicket: {
      closeRequestBtn: 'close request',
    },
    editTicket: {
      editTicketBtnTooltip: 'edit ticket',
    },
  },

  info: {
    fileds: {
      status: 'status',
      ticketCategory: 'ticket category',
      createdOn: 'created on',
      ticketID: 'ticket ID',
      description: 'description',
    },
  },

  form: {
    edit: {
      modalTitle: 'edit ticket',
      fields: {
        title: {
          displayName: 'title',
          placeholder: 'add ticket title...',
        },
        category: {
          displayName: 'category',
          placeholder: 'choose category...',
          categoryOptions: {
            rapidIRInvestigator: 'Rapid-IR Investigator',
            integrations: 'Integrations',
            others: 'Others',
          },
        },
        description: {
          displayName: 'description',
          placeholder: 'add ticket description...',
        },
      },
      footer: {
        confirm: 'edit ticket',
      },
      successToast: {
        message: 'ticket updated successfully',
      },
    },
    create: {
      fields: {
        createMessage: {
          placeholder: 'Write a message...',
          successToast: {
            message: 'message added successfully',
          },
        },
        addFile: {
          display: 'add image',
        },
        uploadFile: {
          display: 'Upload Image (max image size: 5 mb)',
        },
      },
      confirmBtn: 'send',
    },
  },
};

export default ticket;
