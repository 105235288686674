export enum Path {
  Welcome = 'welcome',
  Reports = 'reports',
  SensorsDashboard = 'sensors-dashboard',
  IncidentsActive = 'incidents-active',
  IncidentsHistory = 'incidents-history',
  Secrets = 'secrets',
  Resources = 'resources',
  UsersSettings = 'user-settings',
  Logout = 'logout',
  ConditionalAccess = 'conditional-access',
  CVE = 'cve-2024-3094',
  AccountSettings = 'account-settings',
  Warp = 'file-sharing-status',
  Upload = 'file-sharing-usage',
  AuditOrg = 'audit-org',
  AuditSelf = 'audit-self',
  Findings = 'findings',
  Health = 'health',
  Usage = 'usage',
  CustomerFile = 'customer-file',
  CustomerSupport = 'customer-support',
}
