import React from 'react';

import classes from './PIcon.module.scss';
import icons from '../../assets/icons';
import { IconSize } from './PIcon.model';
import { Align } from 'chart.js';

type Props = {
  name: keyof typeof icons;
  size?: IconSize;
  className?: string;
  dataTestid?: string;
  width?: number;
  height?: number;
  align?: Align;
  fillColor?: string;
};

const PIconView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  if (props.width && props.height && !props.size) {
    return (
      <svg
        className={`${classes['containerForCustomIcon']} ${props.align ? classes[`containerForCustomIcon--${props.align}`] : ''} ${
          props.className || ''
        }`.trim()}
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={'0 0 ' + icons[props.name][0]}
        dangerouslySetInnerHTML={{ __html: icons[props.name][1] }}
        data-testid={props.dataTestid}
        width={props.width}
        height={props.height}
        fill={props.fillColor ? props.fillColor : 'inhirit'}
      ></svg>
    );
  } else {
    return (
      <svg
        className={`${classes['container']} ${props.size ? classes[`container--${props.size}`] : ''} ${props.className || ''}`.trim()}
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={'0 0 ' + icons[props.name][0]}
        dangerouslySetInnerHTML={{ __html: icons[props.name][1] }}
        data-testid={props.dataTestid}
        width={props.width}
        height={props.height}
      ></svg>
    );
  }
};

PIconView.displayName = 'PIconView';

export default PIconView;
