const usage = {
  title: 'usage',
  valueCopied: 'value copied successfully',

  notes: {
    title: 'before installing',
    text1: `Our health check tool allows you to assess the connectivity of the machine running the tool with Profero's backend. These diagnostics will assist in pin-pointing issue for a quicker resolution time.`,
    text2: 'The tool runs the following tests:',
    text3: 'Check DNS resolution for both our backend and public addresses',
    text4: 'Check connectivity to our backend',
    text5: 'Check the responsiveness of our APIs',
    text6: `The tool reports StatusCode = 0 when all tests complete successfully, if there is some issue, you can use the reported code in the Diagnosys screen for details.`,
  },

  usage: {
    title: 'instaling Instructions',
    text1: 'Download our tool to your machine',
    text2: 'Navigate to the folder with our tool',
    text3: 'Execute the following command (actual syntax may vary depending on your version)',
    text4: 'If you get a non-zero status code, use the diagnosys page to get further details.',
  },
};

export default usage;
