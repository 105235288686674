const form = {
  mandatory: '*mandatory',

  commonValidations: {
    hebrewNotAllowed: 'Text is not valid, please use english only',
    filedIsRequired: 'Field is required',
    unsupportedImgaeFormat: 'The file is not valid, please choose PMG or JPEG only',
  },
};

export default form;
