import { GuardData } from './guard.model';
import { FeaturesFlags } from 'models/features-flags';
import { IFlag } from 'models/flag';
import { FlagState } from 'utils/enums/feature-flags';
import { IPermission } from 'models/permission';

const accessGuard = (
  module: FeaturesFlags | { code: FeaturesFlags; action: string },
  modulePermission?: string | string[],
): ((guardData: GuardData) => boolean) => {
  return (guardData: GuardData) => {
    const flag: IFlag | undefined = guardData.store.featureFlags.flags.find((item: IFlag) => item.name === module);
    const home: IFlag | undefined = guardData.store.featureFlags.flags.find((item: IFlag) => item.name === FeaturesFlags.Home);
    const sensors: IFlag | undefined = guardData.store.featureFlags.flags.find((item: IFlag) => item.name === FeaturesFlags.Sensors);
    const incidents: IFlag | undefined = guardData.store.featureFlags.flags.find((item: IFlag) => item.name === FeaturesFlags.Incidents);
    const readiness: IFlag | undefined = guardData.store.featureFlags.flags.find((item: IFlag) => item.name === FeaturesFlags.Readiness);
    const configurations: IFlag | undefined = guardData.store.featureFlags.flags.find(
      (item: IFlag) => item.name === FeaturesFlags.Configurations,
    );
    const setiings: IFlag | undefined = guardData.store.featureFlags.flags.find((item: IFlag) => item.name === FeaturesFlags.Settings);
    const fileSharingFlag: IFlag | undefined = guardData.store.featureFlags.flags.find(
      (item: IFlag) => item.name === FeaturesFlags.FileSharing,
    );

    // If the feature flag in not enabled --> return false (we won't be able direct to its URL)
    if (flag?.state !== FlagState.Enabled) {
      return false;
    }

    // If there is no permission to read the list in the page --> return false (we won't be able direct to its URL)
    if (modulePermission && !Array.isArray(modulePermission)) {
      const isPermitted: string | undefined = guardData.store.auth.permissions.find(
        (permission: IPermission) => permission.permission === modulePermission,
      )?.permission;

      if (!isPermitted) {
        return false;
      }
    } else if (Array.isArray(modulePermission)) {
      let isPermitted: boolean = false;

      for (let i = 0; i < modulePermission.length; i++) {
        const value: string | undefined = guardData.store.auth.permissions.find(
          (permission: IPermission) => permission.permission === modulePermission[i],
        )?.permission;

        if (value) {
          isPermitted = true;
        }
      }

      return isPermitted;
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (home?.state !== FlagState.Enabled) {
      if (
        flag.name === FeaturesFlags.Welcome ||
        flag.name === FeaturesFlags.Reports ||
        flag.name === FeaturesFlags.Findings ||
        flag.name === FeaturesFlags.CustomerSupport
      ) {
        return false;
      }
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (fileSharingFlag?.state !== FlagState.Enabled) {
      if (flag.name === FeaturesFlags.FileSharingStatus || flag.name === FeaturesFlags.FileSharingUsage) {
        return false;
      }
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (readiness?.state !== FlagState.Enabled) {
      if (flag.name === FeaturesFlags.Usage || flag.name === FeaturesFlags.Health) {
        return false;
      }
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (sensors?.state !== FlagState.Enabled) {
      if (flag.name === FeaturesFlags.SensorsDashboard || flag.name === FeaturesFlags.Cve) {
        return false;
      }
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (incidents?.state !== FlagState.Enabled) {
      if (flag.name === FeaturesFlags.IncidentsActive || flag.name === FeaturesFlags.IncidentsHistory) {
        return false;
      }
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (configurations?.state !== FlagState.Enabled) {
      if (flag.name === FeaturesFlags.Resources || flag.name === FeaturesFlags.Secrets || flag.name === FeaturesFlags.ConditionalAccess) {
        return false;
      }
    }

    // If the child is enabled/disabled but the parent in hidden/disabled --> return false (we won't be able direct to its childrens URL)
    if (setiings?.state !== FlagState.Enabled) {
      if (flag.name === FeaturesFlags.UsersSettings || flag.name === FeaturesFlags.CustomerFile || flag.name === FeaturesFlags.Audit) {
        return false;
      }
    }

    return true;
  };
};

export default accessGuard;
