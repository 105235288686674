import React, { ReactNode } from 'react';

import classes from './PToast.module.scss';
import { ToastType } from 'utils/enums/toast-types';
import { ButtonType } from 'views/PButton/PButton.model';
import PButton from 'views/PButton/PButton';
import PIcon from 'views/PIcon/PIcon';

type Props = {
  message?: string | ReactNode;
  type?: ToastType;
  className?: string;
  proggressBarClassName?: string;
  defaultMesage: string;
  onCloseToast: () => void;
};

const PToastView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={`${classes['container']} ${props.type === ToastType.Error ? classes['container--error'] : ''} ${
        props.className || ''
      }`.trim()}
    >
      <div className={classes['message']}>
        <span data-testid="pToasterMessage">{props.message || props.defaultMesage}</span>
        <PButton
          dataTestid="pToasterCloseButton"
          className={classes['close']}
          type={ButtonType.Button}
          color="transparent"
          onClick={props.onCloseToast}
        >
          <PIcon name="close" className={classes['close__icon']} />
        </PButton>
      </div>
      <div className={`${classes['toastProgressBar']} ${props.proggressBarClassName || ''}`.trim()}></div>
    </div>
  );
};

PToastView.displayName = 'PToastView';

export default PToastView;
