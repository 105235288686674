export enum FeaturesFlags {
  Home = 'home', // allows to show the "home" subject in the sidebar
  FileSharing = 'file-sharing', // allows to show the "file sharing" subject in the sidebar
  Readiness = 'readiness', // allows to show the "readiness" subject in the sidebar
  Sensors = 'sensors', // allows to show the "sensors inventory" subject in the sidebar
  Incidents = 'incidents', // allows to show the "incidents" subject in the sidebar
  Configurations = 'configurations', // allows to show the "configurations" subject in the sidebar
  Settings = 'settings', // allows to show the "settings" subject in the sidebar
  Welcome = 'welcome', // allows to show the "status" page under the "home" subject
  Findings = 'findings', // allows to show the "findings" page under the "home" subject
  Reports = 'reports', // allows to show the "reports" page under the "home" subject
  FileSharingStatus = 'file-sharing-status', // allows to show the "warp status" page under the "file sharing" subject
  FileSharingUsage = 'file-sharing-usage', // allows to show the "warp usage" page under the "file sharing" subject
  Usage = 'usage', // allows to show the "usage" page under the "readiness" subject
  Health = 'health', // allows to show the "diagnostic" page under the "readiness" subject
  SensorsDashboard = 'sensors-dashboard', // allows to show the "sensors dashboard" page under the "sensors inventory" subject
  Cve = 'cve-2024-3094', // allows to show the "CVE" page under the "sensors inventory" subject
  IncidentsActive = 'incidents-active', // allows to show the "active" page under the "incidents" subject
  IncidentsHistory = 'incidents-history', // allows to show the "history" page under the "incidents" subject
  Resources = 'resources', // allows to show the "resources" page under the "configurations" subject
  ConditionalAccess = 'conditional-access', // allows to show the "conditional access" page under the "configurations" subject
  Secrets = 'secrets', // allows to show the "secrets" page under the "configurations" subject
  UsersSettings = 'user-settings', // allows to show the "users management" page under the "settings" subject
  CustomerFile = 'customer-file', // allows to show the "customer file" page under the "settings" subject
  Audit = 'audit', // allows to show the "audit org" and "audit" pages under the "settings" subject
  AccountSettings = 'account-settings', // allows to show the account settings page (side bar bottom profile button --> account settings)
  CustomerSupport = 'customer-support', // allows to show the "customer support" page under the "home" subject
}
