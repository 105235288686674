import React from 'react';

import PLoadingView from './PLoading.view';

type Props = {
  show: boolean;
  percent: number | null;
  header?: string;
  autoLogin?: boolean;
};

const PLoading: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <PLoadingView header={props.header} show={props.show} percent={props.percent} autoLogin={props.autoLogin}></PLoadingView>;
};

PLoading.displayName = 'PLoading';

export default PLoading;
