const welcomePage = {
  contactDetails: {
    actions: {
      options: {
        add: 'add contact',
        edit: 'edit contacts list',
        export: 'export contacts list',
      },

      changeDetails: {
        successToast: {
          message: 'contacts updated successfully',
        },

        errorToast: {
          message: 'Could not update contacts',
        },
      },

      copyMessage: 'value copied successfully',
    },

    form: {
      create: {
        title: 'Create new contact',

        fields: {
          name: {
            display: 'Name',
            placeholder: 'Add name',
          },

          title: {
            display: 'Title',
            placeholder: 'Add title',
          },

          email: {
            display: 'Email',
            placeholder: 'Add email',
          },

          phone_num: {
            display: 'Phone',
            placeholder: 'Add phone',
          },
        },

        footer: {
          confirm: 'add contact',
        },

        successToast: {
          message: 'contact added',
        },

        errorToast: {
          message: 'Could not add contact',
        },
      },

      update: {
        title: 'edit contact {{name}}',

        fields: {
          name: {
            display: 'Name',
            placeholder: 'Add name',
          },

          title: {
            display: 'Title',
            placeholder: 'Add title',
          },

          email: {
            display: 'Email',
            placeholder: 'Add email',
          },

          phone_num: {
            display: 'Phone',
            placeholder: 'Add phone',
          },
        },

        footer: {
          confirm: 'save',
        },

        successToast: {
          message: 'contact updated successfuly',
        },

        errorToast: {
          message: 'Could not edit contact',
        },
      },
    },

    table: {
      title: 'Contacts List',

      headers: {
        name: 'name',
        title: 'title',
        phone: 'phone number',
        email: 'email',
      },
    },

    footer: {
      lastModified: 'last modified:',

      btns: {
        cancel: 'cancel',
        save: 'save',
      },
    },
  },

  incidentsdays: {
    title: '# of Incidents days',
  },

  emergencyInfo: {
    title: 'emergency details',
    secretsTitle: 'resources details',
    valueCopied: 'value copied successfully',
    codeName: 'your code name:',
    phone: 'phone',
    email: 'email',
    instalationKey: 'Sensor Key:',
    wrapKey: 'Warp Key:',

    actions: {
      options: {
        edit: 'edit details',
      },

      changeDetails: {
        successToast: {
          message: 'emergency info updated successfully',
        },

        errorToast: {
          message: 'Could not update emergency info',
        },
      },
    },

    contacts: {
      form: {
        create: {
          title: 'Create New emergency info',

          fields: {
            email: {
              display: 'Email',
              placeholder: 'Add email',
            },

            phone_num: {
              display: 'Phone',
              placeholder: 'Add phone',
            },
          },

          footer: {
            confirm: 'add contact',
          },

          successToast: {
            message: 'contact added',
          },

          errorToast: {
            message: 'Could not add contact',
          },
        },

        update: {
          title: 'edit contact',

          fields: {
            email: {
              display: 'Email',
              placeholder: 'Add email',
            },

            phone_num: {
              display: 'Phone',
              placeholder: 'Add phone',
            },
          },

          footer: {
            confirm: 'save',
          },

          successToast: {
            message: 'contact updated successfully',
          },

          errorToast: {
            message: 'Could not update contact',
          },
        },
      },

      delete: {
        successToast: {
          message: 'contact deleted successfully',
        },

        errorToast: {
          message: 'could not delete contact',
        },
      },
    },

    form: {
      updateInfo: {
        title: 'edit information',

        fields: {
          email: {
            display: 'Email',
            placeholder: 'Add email',
          },

          phone: {
            display: 'Phone',
            placeholder: 'Add phone',
          },
        },

        footer: {
          confirm: 'save',
        },
      },

      updateKeys: {
        title: 'edit keys',

        fields: {
          sensorKey: {
            display: 'Sensor installation key',
            placeholder: 'Add key',
          },

          wrapUploaderKey: {
            display: 'Warp uploader key:',
            placeholder: 'Add key',
          },
        },

        footer: {
          confirm: 'save',
        },
      },
    },

    footer: {
      lastModified: 'last modified:',
    },
  },

  reportIncident: {
    title: 'do you have any doubts?',
    report: 'open incident',
    activeIncident: 'there is an active incident',

    confirmModal: {
      incidentActive: 'There is {{count}} active incident. Continue with the new incident?',
      incidentsActive: 'There are {{count}} active incidents. Continue with the new incident?',

      footer: {
        cancel: 'cancel',
        confirm: 'continue',
      },
    },

    form: {
      title: 'new incident',

      fields: {
        notes: {
          display: 'additional notes* (mandatory field)',
          placeholder: 'Add additional notes',
        },

        summary: {
          display: 'incident summary* (mandatory field)',
          placeholder: 'Add a summary',
        },

        name: {
          display: 'name',
        },
      },

      footer: {
        confirm: 'submit',
      },

      successToast: {
        message: 'incident reported successfully',
      },

      errorToast: {
        message: 'could not report incident',
      },
    },
  },

  reports: {
    title: 'last 5 reports',

    table: {
      headers: {
        date: 'date',
        title: 'report name',
      },
    },
  },

  onlineSensors: {
    title: '# of online sensors',

    footer: {
      lastUpdate: 'last update',
    },
  },

  welcomeText: 'Welcome To Profero Rapid-IR',
};

export default welcomePage;
