const findings = {
  title: 'recommendations',

  form: {
    update: {
      fields: {
        note: {
          display: 'note',
          placeholder: 'Please enter note',
        },

        lables: {
          display: 'acount:',
        },

        status: {
          display: 'status',

          options: {
            new: 'NEW',
            regression: 'REGRESSION',
            pending: 'PENDING',
            inProgress: 'IN PROGRESS',
            riskAccepted: 'RISK ACCEPTED',
            completed: 'COMPLETED',
            reolved: 'RESOLVED',
          },
        },
      },

      footer: {
        confirm: 'save',
      },

      successToast: {
        message: 'recommendation updated successfully',
      },
    },
  },

  table: {
    moreInfo: 'more info',
    generalFilterText: 'Search in recommendations',

    extraData: {
      description: 'description',
      history: 'history',
      actions: 'actions to resolve',
      businessImpact: 'Business impact',
      readmore: 'read more',
      edit: 'edit recommendation',

      moreData: {
        impact: 'impact:',
        summary: 'summary:',
        status: 'status:',
        description: 'description',
        business: 'business impact',
        actions: 'actions to resolve',
        history: 'history',
        notes: 'notes',
        exit: 'Exit',
        accountLabel: 'account:',
        category: 'category:',
        title: 'title:',
        identifier: 'identifier:',

        headers: {
          date: 'date',
          user: 'user',
          details: 'details',
        },
      },
    },

    headers: {
      timestamp: 'date & time',
      system_name: 'system',
      updatedAt: 'last update',
    },
  },

  health: {
    search: 'Search in systems',
    noResults: 'No Results',

    filter: {
      status: 'status',
      selectAll: 'select all',
      clearAll: 'clear all',
    },

    modal: {
      lastChecked: 'last status update',

      actions: {
        test: {
          loading: 'running connector test',
          testBtn: 'test',
          testComplete: {
            message: 'the test is complete',
          },
        },
      },

      table: {
        accountName: 'account name',
        accountId: 'account ID',
        roleArn: 'role ARN',
        externalId: 'external ID',
        noAccountsMessage: 'no account connected yet.',
      },

      footer: {
        editBtn: 'edit configurations',
        cancelBtn: 'cancel',
        saveBtn: 'save',
      },
    },
  },

  dashboard: {
    top3findings: 'Top Recommendations',

    scoreGraph: {
      title: 'Score',
      currentScore: 'Current score',
      comparison: 'Comparison Trend',
      last30: 'Last 30 days',
      last3: 'Last 3 Months',
      last12: 'Last 12 Months',
    },

    readinessGraph: {
      title: 'Readiness',
    },

    findingCard: {
      impact: 'Impact',
      title: 'Title:',
      status: 'Status:',
      desc: 'Description',
      history: 'History',
      actions: 'Actions',
      businessImpact: 'Business Impact',
      readmore: 'read more',

      moreData: {
        impact: 'impact:',
        summary: 'summary:',
        status: 'status:',
        description: 'description',
        business: 'business impact',
        actions: 'actions to resolve',
        history: 'history',
        notes: 'notes',
        exit: 'Exit',
        accountLabel: 'account:',
        category: 'category:',
        identifier: 'identifier:',
        title: 'title:',

        headers: {
          date: 'date',
          user: 'user',
          details: 'details',
        },
      },
    },

    extraInfo: {
      avgTime: 'Avg. Mean Time To Resolution',
      time: 'hh:mm',
      health: 'Connectors Health',
      status: 'Healty',
    },
  },
};

export default findings;
