import auth from './auth';
import layout from './layout';
import reports from './reports';
import common from './common';
import resources from './resources';
import secrets from './secrets';
import sensors from './sensors';
import users from './users';
import registrationOTP from './registrationOTP';
import registration from './registration';
import registrationTOTP from './registrationTOTP';
import totp from './totp';
import resetPasswordTOTP from './resetPasswordTOTP';
import resetPasswordFullRecoveryOTP from './resetPasswordFullRecoveryOTP';
import resetPasswordFullRecoveryTOTP from './resetPasswordFullRecoveryTOTP';
import resetPassword from './resetPassword';
import welcomePage from './welcomePage';
import incidentsHistory from './incidentsHistory';
import incidentsActive from './incidentsActive';
import conditionalAccess from './conditionalAccess';
import prospect from './prospect';
import cve from './cve';
import accountSettings from './accountSettings';
import warp from './warp';
import upload from './upload';
import auditOrg from './auditOrg';
import auditSelf from './auditSelf';
import findings from './findings';
import usage from './usage';
import health from './health';
import customerFile from './customerFile';
import customerSupport from './customerSupport';
import ticket from './ticket';

const pages = {
  auth,
  layout,
  reports,
  common,
  resources,
  secrets,
  sensors,
  users,
  registrationOTP,
  registration,
  registrationTOTP,
  totp,
  resetPasswordTOTP,
  resetPasswordFullRecoveryOTP,
  resetPasswordFullRecoveryTOTP,
  resetPassword,
  welcomePage,
  incidentsHistory,
  incidentsActive,
  conditionalAccess,
  prospect,
  cve,
  accountSettings,
  warp,
  upload,
  auditOrg,
  auditSelf,
  findings,
  usage,
  health,
  customerFile,
  customerSupport,
  ticket,
};

export default pages;
