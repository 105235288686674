/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import PAppModalView from './PAppModal.view';

import * as uiActions from '../../store/actions/ui';
import { PAppModalProps } from './PAppModal.model';

type Props = PAppModalProps;

const PAppModal: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const [windowWidthState, setWindowWidthState] = useState<number>(window.innerWidth);
  const [windowHeightState, setWindowHeightState] = useState<number>(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidthState(() => window.innerWidth);
      setWindowHeightState(() => window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const modalRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const confirm = () => {
    props.onConfirm && props.onConfirm();
    dispatch(uiActions.hideAppModal());
  };

  const cancel = () => {
    props.onClose && props.onClose();
  };

  return (
    <PAppModalView
      modalRef={modalRef}
      show={props.show}
      header={props.header}
      body={props.body}
      showDefaultButtons={props.showDefaultButtons}
      showCloseIcon={props.showCloseIcon}
      confirmText={props.confirmText}
      cancelText={props.cancelText}
      headerClassName={props.headerClassName}
      windowWidth={windowWidthState}
      windowHeight={windowHeightState}
      switchButtonsActions={props.switchButtonsActions}
      onConfirm={confirm}
      onCancel={cancel}
      onClickCloseModal={cancel}
    ></PAppModalView>
  );
};

PAppModal.displayName = 'PAppModal';

export default PAppModal;
