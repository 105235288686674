// time definition
export const SECOND_IN_MILLISECOND = 1000;
export const TWO_MINUTES_IN_MILLISECOND = 120000;

// field max length definition
export const MAX_INPUT_FIELD_LENGTH = 150;
export const MAX_TEXT_AREA_FIELD_LENGTH = 400;

// connectorModal default definitions
export const DEFAULT_ACCOUNT_ID = '992382531216';
export const AWS_CONECCTOR_ID = 'd2616a1b-668e-4922-bc15-13d548c4e8e9';
export const DEFAULT_ACCOUNT_ID_FOR_TEST = '087558507542';
export const CONECCTOR_ID_FOR_TEST = 'd2616a1b-668e-4922-bc15-13d548c4e8e9';
export const AWS_MOSULE_NAME = 'aws';
